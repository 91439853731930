// Dashboard.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import api, { setAuthToken } from '../services/api'; // Importer api et setAuthToken
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';

// Enregistrer les composants nécessaires de Chart.js
Chart.register(...registerables);

// Fonction pour générer des couleurs aléatoires
const generateRandomColors = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const hue = (i * 137.5) % 360; // Utilise le nombre d'or pour une distribution uniforme des couleurs
    colors.push(`hsl(${hue}, 70%, 60%)`);
  }
  return colors;
};

const Dashboard = ({ selectedPeriod }) => {
  const { user } = useAuth();
  const [kpiData, setKpiData] = useState(null);
  const [productStats, setProductStats] = useState(null);
  const [selectedTab, setSelectedTab] = useState('global');
  const [isAdmin, setIsAdmin] = useState(false);
  const chartsRef = useRef({});
  const [shelfStats, setShelfStats] = useState(null);
  const [sessionStats, setSessionStats] = useState(null);

  useEffect(() => {
    if (user && user.roles) {
      setIsAdmin(user.roles.includes('admin'));
    }
  }, [user]);

  useEffect(() => {
    if (user?.token) {
      setAuthToken(user.token);
    } else {
      setAuthToken(null);
    }
  }, [user?.token]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.token) return;

      try {
        // Utiliser l'instance Axios globale
        const kpiResponse = await api.get(`/kpi?period=${selectedPeriod}`);
        setKpiData(kpiResponse.data);

        const productStatsResponse = await api.get(`/kpi-product?period=${selectedPeriod}`);
        setProductStats(productStatsResponse.data);

        const shelfStatsResponse = await api.get(`/kpi-shelf?period=${selectedPeriod}`);
        setShelfStats(shelfStatsResponse.data);

        const sessionStatsResponse = await api.get(`/kpi-session?period=${selectedPeriod}`);
        setSessionStats(sessionStatsResponse.data);
      } catch (error) {
        console.error('Error fetching KPI data:', error);
      }
    };

    fetchData();
  }, [selectedPeriod, user]);

  const destroyCharts = useCallback(() => {
    Object.values(chartsRef.current).forEach(chart => {
      if (chart) {
        chart.destroy();
      }
    });
    chartsRef.current = {};
  }, []);

  const renderCharts = useCallback(() => {
    destroyCharts();

    if (selectedTab === 'global') {
      const ctx1 = document.getElementById('inventorySessionsChart')?.getContext('2d');
      if (ctx1 && kpiData && kpiData.labels && kpiData.inventorySessionCounts) {
        chartsRef.current.inventorySessionsChart = new Chart(ctx1, {
          type: 'line',
          data: {
            labels: kpiData.labels,
            datasets: [{
              label: 'Number of Inventory Sessions',
              data: kpiData.inventorySessionCounts,
              borderColor: '#FF5733',
              fill: false,
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }

      const ctx2 = document.getElementById('detectionDurationChart')?.getContext('2d');
      if (ctx2 && kpiData && kpiData.shelfNames && kpiData.avgDetectionDurations) {
        const backgroundColors = generateRandomColors(kpiData.shelfNames.length);
        chartsRef.current.detectionDurationChart = new Chart(ctx2, {
          type: 'pie',
          data: {
            labels: kpiData.shelfNames,
            datasets: [{
              data: kpiData.avgDetectionDurations,
              backgroundColor: backgroundColors
            }]
          }
        });
      }
    }

    if (selectedTab === 'adjustments') {
      const ctx3 = document.getElementById('inventoryAdjustmentsChart')?.getContext('2d');
      if (ctx3 && kpiData && kpiData.labels && kpiData.inventoryAdjustmentsCount) {
        const backgroundColors = generateRandomColors(kpiData.labels.length);
        chartsRef.current.inventoryAdjustmentsChart = new Chart(ctx3, {
          type: 'bar',
          data: {
            labels: kpiData.labels,
            datasets: [{
              label: 'Number of Adjustments',
              data: kpiData.inventoryAdjustmentsCount,
              backgroundColor: backgroundColors,
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }

      const ctx4 = document.getElementById('productAdjustmentPercentageChart')?.getContext('2d');
      if (ctx4 && kpiData && kpiData.labels && kpiData.adjustedProductsPercentage) {
        chartsRef.current.productAdjustmentPercentageChart = new Chart(ctx4, {
          type: 'bar',
          data: {
            labels: kpiData.labels,
            datasets: [
              {
                label: 'Percentage of Adjusted Products',
                data: kpiData.adjustedProductsPercentage,
                backgroundColor: '#FF5733',
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                  callback: (value) => `${value}%`,
                },
              },
            },
          },
        });
      }

      const ctx5 = document.getElementById('adjustmentReasonsChart')?.getContext('2d');
      if (ctx5 && kpiData && kpiData.adjustmentReasons) {
        const filteredReasons = Object.entries(kpiData.adjustmentReasons || {})
          .filter(([, count]) => count > 0);

        const labels = filteredReasons.map(([reason]) => reason);
        const data = filteredReasons.map(([, count]) => count);
        const backgroundColors = generateRandomColors(labels.length);

        chartsRef.current.adjustmentReasonsChart = new Chart(ctx5, {
          type: 'pie',
          data: {
            labels: labels,
            datasets: [
              {
                data: data,
                backgroundColor: backgroundColors,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Reasons for Non-Zero Adjustments'
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    const label = context.label || '';
                    const value = context.parsed || 0;
                    const total = context.dataset.data.reduce((acc, current) => acc + current, 0);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return `${label}: ${value} (${percentage}%)`;
                  }
                }
              }
            }
          }
        });
      }

      const ctx6 = document.getElementById('userAdjustmentsChart')?.getContext('2d');
      if (ctx6 && kpiData && kpiData.userAdjustments) {
        chartsRef.current.userAdjustmentsChart = new Chart(ctx6, {
          type: 'bar',
          data: {
            labels: Object.keys(kpiData.userAdjustments || {}),
            datasets: [
              {
                label: 'Number of Adjustments',
                data: Object.values(kpiData.userAdjustments || {}),
                backgroundColor: '#FF5733',
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    }

    if (selectedTab === 'productStats') {
      const ctx7 = document.getElementById('totalQuantityByProductChart')?.getContext('2d');
      if (ctx7 && productStats && productStats.totalQuantityByProduct) {
        const labels = Object.keys(productStats.totalQuantityByProduct || {});
        const data = Object.values(productStats.totalQuantityByProduct || {});
        const backgroundColors = generateRandomColors(labels.length);

        chartsRef.current.totalQuantityByProductChart = new Chart(ctx7, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [{
              label: 'Total Quantity',
              data: data,
              backgroundColor: backgroundColors,
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          }
        });
      }

      const ctx8 = document.getElementById('quantityVariationOverTimeChart')?.getContext('2d');
      if (ctx8 && productStats && productStats.quantityVariationOverTime) {
        const labels = Object.keys(productStats.quantityVariationOverTime);
        const products = Object.keys(productStats.quantityVariationOverTime[labels[0]]);
        const backgroundColors = generateRandomColors(products.length);

        const datasets = products.map((product, index) => {
          return {
            label: product,
            data: labels.map(date => productStats.quantityVariationOverTime[date][product]),
            borderColor: backgroundColors[index],
            fill: false,
          };
        });

        chartsRef.current.quantityVariationOverTimeChart = new Chart(ctx8, {
          type: 'line',
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }

      const ctx9 = document.getElementById('mostAdjustedProductsChart')?.getContext('2d');
      if (ctx9 && productStats && productStats.mostAdjustedProducts) {
        const labels = Object.keys(productStats.mostAdjustedProducts || {});
        const data = Object.values(productStats.mostAdjustedProducts || {});
        const backgroundColors = generateRandomColors(labels.length);

        chartsRef.current.mostAdjustedProductsChart = new Chart(ctx9, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Number of Adjustments',
                data: data,
                backgroundColor: backgroundColors,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: false
              }
            }
          },
        });
      }
    }

    if (selectedTab === 'shelfStats' && shelfStats) {
      const ctx = document.getElementById('productsPerShelfChart')?.getContext('2d');
      if (ctx && shelfStats.shelfNames && shelfStats.productCounts) {
        chartsRef.current.productsPerShelfChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: shelfStats.shelfNames,
            datasets: [{
              label: 'Number of Products',
              data: shelfStats.productCounts,
              backgroundColor: '#FF5733',
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }

      const ctx2 = document.getElementById('detectedVsAdjustedChart')?.getContext('2d');
      if (ctx2 && shelfStats.shelfNamesDA && shelfStats.detectedQuantities && shelfStats.adjustedQuantities) {
        chartsRef.current.detectedVsAdjustedChart = new Chart(ctx2, {
          type: 'bar',
          data: {
            labels: shelfStats.shelfNamesDA,
            datasets: [
              {
                label: 'Detected Quantity',
                data: shelfStats.detectedQuantities,
                backgroundColor: '#FF5733',
              },
              {
                label: 'Adjusted Quantity',
                data: shelfStats.adjustedQuantities,
                backgroundColor: '#33FF57',
              }
            ]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }

      const ctx3 = document.getElementById('shelfOccupancyChart')?.getContext('2d');
      if (ctx3 && shelfStats.shelfNamesOccupancy && shelfStats.occupancyRates) {
        chartsRef.current.shelfOccupancyChart = new Chart(ctx3, {
          type: 'bar',
          data: {
            labels: shelfStats.shelfNamesOccupancy,
            datasets: [{
              label: 'Occupancy Rate (%)',
              data: shelfStats.occupancyRates,
              backgroundColor: '#3357FF',
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                  callback: function(value) {
                    return value + '%';
                  }
                }
              }
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return context.parsed.y.toFixed(2) + '%';
                  }
                }
              }
            }
          }
        });
      }
    }

    if (selectedTab === 'sessionStats' && sessionStats && sessionStats.sessions) {
      const ctx1 = document.getElementById('sessionDurationChart')?.getContext('2d');
      if (ctx1) {
        chartsRef.current.sessionDurationChart = new Chart(ctx1, {
          type: 'bar',
          data: {
            labels: sessionStats.sessions.map(session => session.name),
            datasets: [{
              label: 'Duration (minutes)',
              data: sessionStats.sessions.map(session => session.duration / 60),
              backgroundColor: '#4e73df',
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Duration (minutes)'
                }
              }
            },
            plugins: {
              title: {
                display: true,
                text: 'Session Duration'
              }
            }
          }
        });
      }

      const ctx2 = document.getElementById('sessionProductCountChart')?.getContext('2d');
      if (ctx2) {
        chartsRef.current.sessionProductCountChart = new Chart(ctx2, {
          type: 'bar',
          data: {
            labels: sessionStats.sessions.map(session => session.name),
            datasets: [{
              label: 'Product Count',
              data: sessionStats.sessions.map(session => session.product_count),
              backgroundColor: '#1cc88a',
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Product Count'
                }
              }
            },
            plugins: {
              title: {
                display: true,
                text: 'Products Counted per Session'
              }
            }
          }
        });
      }

      const ctx3 = document.getElementById('sessionTrendChart')?.getContext('2d');
      if (ctx3) {
        chartsRef.current.sessionTrendChart = new Chart(ctx3, {
          type: 'line',
          data: {
            labels: sessionStats.sessions.map(session => session.start_time),
            datasets: [{
              label: 'Duration (minutes)',
              data: sessionStats.sessions.map(session => session.duration / 60),
              borderColor: '#4e73df',
              fill: false
            }, {
              label: 'Product Count',
              data: sessionStats.sessions.map(session => session.product_count),
              borderColor: '#1cc88a',
              fill: false
            }]
          },
          options: {
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day'
                }
              },
              y: {
                beginAtZero: true
              }
            },
            plugins: {
              title: {
                display: true,
                text: 'Session Trends Over Time'
              }
            }
          }
        });
      }
    }
  }, [kpiData, productStats, shelfStats, sessionStats, selectedTab, destroyCharts]);

  useEffect(() => {
    if (kpiData && productStats && shelfStats && sessionStats) {
      renderCharts();
    }
  }, [kpiData, productStats, shelfStats, sessionStats, renderCharts]);

  if (!kpiData || !productStats || !shelfStats || !sessionStats) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1 className="title">Dashboard</h1>
      <div className="tabs is-toggle is-fullwidth">
        <ul>
          <li className={selectedTab === 'global' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('global')}>Global view</a>
          </li>
          <li className={selectedTab === 'adjustments' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('adjustments')}>Inventory adjustments</a>
          </li>
          <li className={selectedTab === 'productStats' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('productStats')}>Product statistics</a>
          </li>
          <li className={selectedTab === 'shelfStats' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('shelfStats')}>Shelf statistics</a>
          </li>
          <li className={selectedTab === 'sessionStats' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('sessionStats')}>Inventory session statistics</a>
          </li>
        </ul>
      </div>

      <div className={selectedTab === 'global' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <p className="subtitle has-text-centered">Inventory Sessions Chart</p>
            <canvas id="inventorySessionsChart"></canvas>
          </div>
          <div className="column is-half">
            <p className="subtitle has-text-centered">Detection Duration Chart</p>
            <canvas id="detectionDurationChart"></canvas>
          </div>
        </div>
      </div>

      <div className={selectedTab === 'adjustments' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <p className="subtitle has-text-centered">Inventory Adjustments Chart</p>
            <canvas id="inventoryAdjustmentsChart"></canvas>
          </div>
          <div className="column is-half">
            <p className="subtitle has-text-centered">Product Adjustment Percentage Chart</p>
            <canvas id="productAdjustmentPercentageChart"></canvas>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <p className="subtitle has-text-centered">Adjustment Reasons Chart</p>
            <canvas id="adjustmentReasonsChart"></canvas>
          </div>
          <div className="column is-half">
            <p className="subtitle has-text-centered">User Adjustments Chart</p>
            <canvas id="userAdjustmentsChart"></canvas>
          </div>
        </div>
      </div>

      <div className={selectedTab === 'productStats' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <p className="subtitle has-text-centered">Total Quantity by Product Chart</p>
            <canvas id="totalQuantityByProductChart"></canvas>
          </div>
          <div className="column is-half">
            <p className="subtitle has-text-centered">Quantity Variation Over Time Chart</p>
            <canvas id="quantityVariationOverTimeChart"></canvas>
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">
            <p className="subtitle has-text-centered">Most Adjusted Products Chart</p>
            <canvas id="mostAdjustedProductsChart"></canvas>
          </div>
        </div>
      </div>

      <div className={selectedTab === 'shelfStats' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <p className="subtitle has-text-centered">Products per Shelf</p>
            <canvas id="productsPerShelfChart"></canvas>
          </div>
          <div className="column is-half">
            <p className="subtitle has-text-centered">Detected vs Adjusted Quantities per Shelf</p>
            <canvas id="detectedVsAdjustedChart"></canvas>
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">
            <p className="subtitle has-text-centered">Shelf Occupancy Rate</p>
            <canvas id="shelfOccupancyChart"></canvas>
          </div>
        </div>
      </div>

      <div className={selectedTab === 'sessionStats' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <p className="subtitle has-text-centered">Session Duration</p>
            <canvas id="sessionDurationChart"></canvas>
          </div>
          <div className="column is-half">
            <p className="subtitle has-text-centered">Products Counted per Session</p>
            <canvas id="sessionProductCountChart"></canvas>
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">
            <p className="subtitle has-text-centered">Session Trends Over Time</p>
            <canvas id="sessionTrendChart"></canvas>
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">
            <h2 className="subtitle">Session Statistics Summary</h2>
            <p>Total Sessions: {sessionStats?.totalSessions}</p>
            <p>Average Duration: {sessionStats?.avgDuration ? (sessionStats.avgDuration / 60).toFixed(2) : 0} minutes</p>
            <p>Average Products Counted: {sessionStats?.avgProducts ? sessionStats.avgProducts.toFixed(2) : 0}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
