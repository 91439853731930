// Stock.js
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import api, { setAuthToken } from '../services/api';

function Stock() {
  const { user } = useAuth();
  const [stocks, setStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProductDetails, setSelectedProductDetails] = useState(null);

  const isAdmin = user?.roles?.includes('admin');

  const fetchStocks = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await api.get('/stocks');
      const data = response.data;

      // Sort stocks by update date in descending order
      data.stocks.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      // Group stocks by entity if admin
      if (isAdmin) {
        const groupedStocks = data.stocks.reduce((acc, stock) => {
          if (!acc[stock.entity_id]) {
            acc[stock.entity_id] = {
              entityName: stock.entity_name,
              stocks: []
            };
          }
          acc[stock.entity_id].stocks.push(stock);
          return acc;
        }, {});
        setStocks(groupedStocks);
      } else {
        setStocks(data.stocks);
      }
    } catch (error) {
      console.error("Error fetching stocks:", error);
      setErrorMessage("Failed to fetch stocks. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (user && user.token) {
      setAuthToken(user.token);
      fetchStocks();
    } else {
      setAuthToken(null);
    }
  }, [user, fetchStocks]);

  const refreshStocks = () => {
    fetchStocks();
  };

  const exportStocks = (format, entityId = null) => {
    let url = `/stocks/export/${format}`;
    const params = new URLSearchParams();
    if (entityId) {
      params.append('entity_id', entityId);
    }
    window.open(`${api.defaults.baseURL}${url}?${params.toString()}`, '_blank');
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return new Date(dateString).toLocaleString(navigator.language, options);
  };

  const getStockDetails = async (productId) => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await api.get(`/stocks/details/${productId}`);
      setSelectedProductDetails(response.data);
      setModalIsOpen(true);
    } catch (error) {
      console.error("Error fetching stock details:", error);
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage("Product not found or no stock available.");
        } else {
          setErrorMessage(`Error: ${error.response.data.detail || 'Unknown error occurred'}`);
        }
      } else if (error.request) {
        setErrorMessage("No response received from server. Please try again.");
      } else {
        setErrorMessage("Error occurred while fetching data. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProductDetails(null);
  };

  const renderStockTable = (stocks, entityId = null) => (
    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th className="has-text-left is-size-7">Product</th>
          <th className="has-text-left is-size-7">Total Quantity</th>
          <th className="has-text-left is-size-7">Last Updated</th>
          <th className="has-text-left is-size-7">Details</th>
        </tr>
      </thead>
      <tbody>
        {stocks.map((stock) => (
          <tr key={`${stock.product_id}-${stock.shelf_id}-${stock.entity_id}`}>
            <td className="has-text-left is-size-7">{stock.product_name}</td>
            <td className="has-text-left is-size-7">{stock.quantity}</td>
            <td className="has-text-left is-size-7">{formatDate(stock.updated_at)}</td>
            <td className="has-text-left is-size-7">
              <button className="button is-small is-info is-light" onClick={() => getStockDetails(stock.product_id)}>
                View Details
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <h1 className="title">Stock</h1>
      <h2 className="subtitle">Visualisation of stock</h2>

      <div className="buttons">
        <button className="button is-info is-light is-small mb-4" onClick={refreshStocks} disabled={isLoading}>
          <span className="icon">
            <i className="fas fa-sync-alt"></i>
          </span>
          <span>Refresh</span>
        </button>
        <button className="button is-primary is-light is-small mb-4" onClick={() => exportStocks('csv')}>
          <span className="icon">
            <i className="fas fa-file-csv"></i>
          </span>
          <span>Export CSV</span>
        </button>
        <button className="button is-primary is-light is-small mb-4" onClick={() => exportStocks('json')}>
          <span className="icon">
            <i className="fas fa-file-code"></i>
          </span>
          <span>Export JSON</span>
        </button>
        <button className="button is-primary is-light is-small mb-4" onClick={() => exportStocks('xml')}>
          <span className="icon">
            <i className="fas fa-file-alt"></i>
          </span>
          <span>Export XML</span>
        </button>
        <button className="button is-primary is-light is-small mb-4" onClick={() => exportStocks('excel')}>
          <span className="icon">
            <i className="fas fa-file-excel"></i>
          </span>
          <span>Export Excel</span>
        </button>
      </div>

      {isLoading && <div>Loading...</div>}
      {errorMessage && <div className="notification is-danger">{errorMessage}</div>}

      {isAdmin ? (
        Object.entries(stocks).map(([entityId, entityData]) => (
          <div key={entityId} className="mb-6">
            <h3 className="title is-4">{entityData.entityName}</h3>
            <div className="buttons">
              <button className="button is-primary is-light is-small" onClick={() => exportStocks('csv', entityId)}>Export CSV</button>
              <button className="button is-primary is-light is-small" onClick={() => exportStocks('json', entityId)}>Export JSON</button>
              <button className="button is-primary is-light is-small" onClick={() => exportStocks('xml', entityId)}>Export XML</button>
              <button className="button is-primary is-light is-small" onClick={() => exportStocks('excel', entityId)}>Export Excel</button>
            </div>
            {renderStockTable(entityData.stocks, entityId)}
          </div>
        ))
      ) : (
        renderStockTable(stocks)
      )}

      {/* Modal pour les détails du stock */}
      {modalIsOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                Stock Details for {selectedProductDetails?.productName || 'Product'}
              </p>
              <button className="delete" aria-label="close" onClick={closeModal}></button>
            </header>
            <section className="modal-card-body">
              {Object.entries(selectedProductDetails?.entities || {}).map(([entityId, entityData]) => (
                <div key={entityId}>
                  <h3 className="title is-4">{entityData.entityName}</h3>
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Storage Area</th>
                        <th>Rack</th>
                        <th>Shelf</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entityData.locations.map((location, index) => (
                        <tr key={index}>
                          <td>{location.storageArea}</td>
                          <td>{location.rack}</td>
                          <td>{location.shelf}</td>
                          <td>{location.quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
              {Object.keys(selectedProductDetails?.entities || {}).length === 0 && (
                <p>{selectedProductDetails?.message || 'No stock information available.'}</p>
              )}
            </section>
            <footer className="modal-card-foot">
              <button className="button" onClick={closeModal}>Close</button>
            </footer>
          </div>
        </div>
      )}
    </div>
  );
}

export default Stock;