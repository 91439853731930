// Sessions.js
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import api, { setAuthToken } from '../services/api';

function Sessions() {
  const { user } = useAuth();
  const [inventorySessions, setInventorySessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sessionDetailsModal, setSessionDetailsModal] = useState({
    isOpen: false,
    session: {
      shelfInventories: [],
    },
  });

  const fetchInventorySessions = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await api.get('/inventory-sessions');
      setInventorySessions(response.data);
    } catch (error) {
      console.error("Error fetching inventory sessions:", error);
      setErrorMessage("Failed to fetch inventory sessions. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user && user.token) {
      setAuthToken(user.token);
      fetchInventorySessions();
    } else {
      setAuthToken(null);
    }
  }, [user, fetchInventorySessions]);

  const deleteSession = async (sessionId) => {
    try {
      await api.delete(`/inventory-sessions/${sessionId}`);
      setInventorySessions(inventorySessions.filter(session => session.id !== sessionId));
    } catch (error) {
      console.error(`Error deleting session with ID ${sessionId}:`, error);
      setErrorMessage("Failed to delete session. Please try again.");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const correctedDate = new Date(date.getTime() - userTimezoneOffset);
    return correctedDate.toLocaleDateString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const openSessionDetailsModal = (session) => {
    setSessionDetailsModal({
      isOpen: true,
      session: session,
    });
  };

  const closeSessionDetailsModal = () => {
    setSessionDetailsModal({
      isOpen: false,
      session: { shelfInventories: [] },
    });
  };

  const getTotalDetectedObjects = (session) => {
    let total = 0;
    for (const shelfInventory of session.shelfInventories) {
      for (const productData of Object.values(shelfInventory.products)) {
        total += productData.detected_quantity;
      }
    }
    return total;
  };

  const hasAdjustedQuantity = (shelfInventory) => {
    for (const productData of Object.values(shelfInventory.products)) {
      if (productData.adjusted_quantity > 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      <h1 className="title">Inventory sessions</h1>
      <h2 className="subtitle">Show live & historical inventory sessions</h2>

      {isLoading && <div>Loading...</div>}
      {errorMessage && <div className="notification is-danger">{errorMessage}</div>}

      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-text-left is-size-7">Date</th>
            <th className="has-text-left is-size-7">Name</th>
            <th className="has-text-left is-size-7">Duration</th>
            <th className="has-text-left is-size-7">Items</th>
            <th className="has-text-right is-size-7">Actions</th>
          </tr>
        </thead>
        <tbody>
          {inventorySessions.map((session) => (
            <tr key={session.id}>
              <td className="has-text-left is-size-7">{formatDate(session.created_at)}</td>
              <td className="has-text-left is-size-7">{session.name}</td>
              <td className="has-text-left is-size-7">{session.duration ? `${session.duration} secondes` : '-'}</td>
              <td className="has-text-left is-size-7">{getTotalDetectedObjects(session)}</td>
              <td className="has-text-right">
                <button className="button is-primary is-light is-small" onClick={() => openSessionDetailsModal(session)}>
                  <span className="icon is-small">
                    <i className="fas fa-info-circle"></i> 
                  </span>
                  <span>Details</span>
                </button>
                <button className="button is-danger is-light is-small" onClick={() => deleteSession(session.id)}>
                  <span className="icon is-small">
                    <i className="fas fa-trash-alt"></i>
                  </span>
                  <span>Delete</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal pour afficher les détails de la session d'inventaire */}
      <div className={`modal ${sessionDetailsModal.isOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeSessionDetailsModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Session Details</p>
            <button className="delete" aria-label="close" onClick={closeSessionDetailsModal}></button>
          </header>
          <section className="modal-card-body">
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th className="has-text-left is-size-7">Shelf</th>
                  <th className="has-text-left is-size-7">Product</th>
                  <th className="has-text-left is-size-7">Detected quantity</th>
                  <th className="has-text-left is-size-7">Adjusted quantity</th>
                </tr>
              </thead>
              <tbody>
                {sessionDetailsModal.session.shelfInventories.map((shelfInventory) => (
                  <tr key={shelfInventory.id} className={hasAdjustedQuantity(shelfInventory) ? 'has-background-warning' : ''}>
                    <td className="has-text-left is-size-7">{shelfInventory.shelfName}</td>
                    <td className="has-text-left is-size-7">
                      <ul>
                        {Object.keys(shelfInventory.products).map((productName) => (
                          <li key={productName}>{productName}</li>
                        ))}
                      </ul>
                    </td>
                    <td className="has-text-left is-size-7">
                      <ul>
                        {Object.values(shelfInventory.products).map((productData, index) => (
                          <li key={index}>{productData.detected_quantity}</li>
                        ))}
                      </ul>
                    </td>
                    <td className="has-text-left is-size-7">
                      <ul>
                        {Object.values(shelfInventory.products).map((productData, index) => (
                          <li key={index}>{productData.adjusted_quantity}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-danger is-light is-small" onClick={closeSessionDetailsModal}>Close</button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Sessions;