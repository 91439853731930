// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import Entities from './components/Entities';
import StorageAreas from './components/StorageAreas';
import Racks from './components/Racks';
import Products from './components/Products';
import Categories from './components/Categories';
import Stock from './components/Stock';
import Sessions from './components/Sessions';
import PrivateRoute from './components/PrivateRoute';
import Unauthorized from './components/Unauthorized'; 
import 'bulma/css/bulma.min.css';

import './App.css';

function App() {
  console.log("App component rendered");
  const { user, authenticated } = useAuth();
  const [initializing, setInitializing] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState('lastweek'); 
  
  useEffect(() => {
    console.log("App useEffect triggered");
    if (authenticated !== null) {
      setInitializing(false);
    }
  }, [authenticated]);

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  if (initializing) {
    console.log("App is initializing");
    return <div>Loading...</div>;
  }

  if (!authenticated) {
    console.log("Not authenticated");
    return <div>Loading</div>;
  }

  console.log("Rendering main App content, user:", user);
  return (
    <Router>
      <Navbar user={user} onPeriodChange={handlePeriodChange} />
      <div className="container mt-5 main-content">
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard selectedPeriod={selectedPeriod} />} />
            <Route path="/storageareas" element={<StorageAreas />} />
            <Route path="/racks" element={<Racks />} />
            <Route path="/products" element={<Products />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/sessions" element={<Sessions />} />
          </Route>
          
          <Route element={<PrivateRoute roles={['admin']} />}>
            <Route path="/users" element={<Users />} />
            <Route path="/entities" element={<Entities />} />
          </Route>
          
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;